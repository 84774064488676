exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-certifeye-js": () => import("./../../../src/pages/case-studies/certifeye.js" /* webpackChunkName: "component---src-pages-case-studies-certifeye-js" */),
  "component---src-pages-case-studies-cwp-js": () => import("./../../../src/pages/case-studies/cwp.js" /* webpackChunkName: "component---src-pages-case-studies-cwp-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-manualmaster-js": () => import("./../../../src/pages/case-studies/manualmaster.js" /* webpackChunkName: "component---src-pages-case-studies-manualmaster-js" */),
  "component---src-pages-case-studies-manualmasterlearning-js": () => import("./../../../src/pages/case-studies/manualmasterlearning.js" /* webpackChunkName: "component---src-pages-case-studies-manualmasterlearning-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-applications-js": () => import("./../../../src/pages/mobile-applications.js" /* webpackChunkName: "component---src-pages-mobile-applications-js" */),
  "component---src-pages-outsourcing-js": () => import("./../../../src/pages/outsourcing.js" /* webpackChunkName: "component---src-pages-outsourcing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-syntax-serenity-js": () => import("./../../../src/pages/syntax-serenity.js" /* webpackChunkName: "component---src-pages-syntax-serenity-js" */),
  "component---src-pages-system-migrations-js": () => import("./../../../src/pages/system-migrations.js" /* webpackChunkName: "component---src-pages-system-migrations-js" */)
}

